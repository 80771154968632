import { DiyRouteRecordRaw } from "../routes";
import BlankLayout from "@/layouts/BlankLayout.vue";

/* webpackChunkName: "xrworld-myworld-edit" */

export const xrstoreRoutes: Array<DiyRouteRecordRaw> = [
  {
    path: "/xr-store",
    name: "XRStore",
    component: BlankLayout,
    redirect: "/xr-store/model",
    children: [
      {
        name: "模型库",
        path: "/xr-store/model",
        component: () => import("@/pages/xr-store/model.vue"),
      },
      {
        name: "场景库",
        path: "/xr-store/scene",
        component: () => import("@/pages/xr-store/scene.vue"),
      },
      {
        name: "空间库",
        path: "/xr-store/space",
        component: () => import("@/pages/xr-store/space.vue"),
      },
      {
        name: "AB包",
        path: "/xr-store/ab",
        component: () => import("@/pages/xr-store/ab.vue"),
      },
      {
        name: "点云",
        path: "/xr-store/points",
        component: () => import("@/pages/xr-store/points.vue"),
      },
      {
        name: "2D资源",
        path: "/xr-store/material",
        component: () => import("@/pages/xr-store/material.vue"),
      },
    ],
  },
];
export const xreditorRoutes: Array<DiyRouteRecordRaw> = [
  {
    path: "/xr-editor",
    name: "XREditor",
    component: BlankLayout,
    redirect: "/xr-editor/model",
    children: [
      {
        name: "模型编辑器",
        path: "/xr-editor/model",
        meta: {
          url: "/xrwebgl/editor?type=0",
        },
        // component: () => import("@/pages/xr-store/model.vue"),
      },
      {
        name: "场景编辑器",
        path: "/xr-editor/scene",
        meta: {
          url: "/xrwebgl/editor?type=1",
        },
        // component: () => import("@/pages/xr-store/scene.vue"),
      },
      {
        name: "空间编辑器",
        path: "/xr-editor/space",
        meta: {
          url: "/xrwebgl/editor?type=2",
        },
        // component: () => import("@/pages/xr-store/space.vue"),
      },
    ],
  },
];
export const xrswordRoutes: Array<DiyRouteRecordRaw> = [
  {
    name: "XRWorld",
    path: "/xr-world",
    component: BlankLayout,
    redirect: "/xr-world/my-world",
    children: [
      {
        name: "MyWorld",
        path: "/xr-world/my-world",
        component: () => import("@/pages/xr-world/my-world/index.vue"),
        // children: [],
      },
      // {
      //   name: "MyView",
      //   path: "/xr-world/my-view",
      //   component: () => import("@/pages/xr-world/my-view/index.vue"),
      // },
    ],
  },
];

export const navRoutes: Array<DiyRouteRecordRaw> = [
  ...xrstoreRoutes,
  ...xreditorRoutes,
  ...xrswordRoutes,
  {
    name: "XRView",
    path: "/xr-view",
    component: () => import("@/pages/xr-world/my-view/index.vue"),
  },
];

export const myRoutes: Array<DiyRouteRecordRaw> = [
  {
    path: "/home",
    name: "我的主页",
    component: BlankLayout,
    redirect: "/home/model",
    children: [
      {
        name: "我的模型",
        path: "/home/model",
        component: () => import("@/pages/home/model.vue"),
      },
      {
        name: "我的场景",
        path: "/home/scene",
        component: () => import("@/pages/home/scene.vue"),
      },
      {
        name: "我的空间",
        path: "/home/space",
        component: () => import("@/pages/home/space.vue"),
      },
      {
        name: "我的AB包",
        path: "/home/ab",
        component: () => import("@/pages/home/ab.vue"),
      },
      {
        name: "我的点云",
        path: "/home/points",
        component: () => import("@/pages/home/points.vue"),
      },
      {
        name: "我的2D资源",
        path: "/home/material",
        component: () => import("@/pages/home/material.vue"),
      },
    ],
  },
];
export const assetsRoutes: Array<DiyRouteRecordRaw> = [
  {
    path: "/assets/preview",
    name: "预览",
    component: BlankLayout,
    children: [
      // {
      //   name: "图片预览",
      //   path: "/assets/preview/image",
      //   component: () => import("@/pages/assets/preview/image.vue"),
      // },
      // {
      //   name: "媒体预览",
      //   path: "/assets/preview/media",
      //   component: () => import("@/pages/assets/preview/media.vue"),
      // },
      {
        name: "2D资源预览",
        path: "/assets/preview/material",
        component: () => import("@/pages/assets/preview/material.vue"),
      },
      {
        name: "模型预览",
        path: "/assets/preview/model",
        component: () => import("@/pages/assets/preview/model.vue"),
      },
      {
        name: "场景预览",
        path: "/assets/preview/scene",
        component: () => import("@/pages/assets/preview/scene.vue"),
      },
      {
        name: "空间预览",
        path: "/assets/preview/space",
        component: () => import("@/pages/assets/preview/space.vue"),
      },

      {
        name: "房间预览",
        path: "/assets/preview/room",
        component: () => import("@/pages/assets/preview/room.vue"),
      },
    ],
  },
  {
    name: "上传资源",
    path: "/assets/upload",
    component: () => import("@/pages/assets/upload/index.vue"),
  },
  {
    name: "编辑资源",
    path: "/assets/edit",
    component: BlankLayout,
    children: [
      {
        name: "素材",
        path: "/assets/edit/material",
        component: () => import("@/pages/assets/edit/material.vue"),
      },
    ],
  },
];

const routes: Array<DiyRouteRecordRaw> = [
  ...navRoutes,
  ...myRoutes,
  {
    name: "登录二维码",
    path: "/home/qrcode",
    component: () => import("@/pages/home/qrcode.vue"),
  },
  {
    name: "房间编辑",
    path: "/xr-world/my-world/edit",
    component: () => import("@/pages/xr-world/my-world/edit.vue"),
  },
  ...assetsRoutes,
];
export default routes;
